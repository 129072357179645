<template>
    <div>
        <portal to="destination">
            <!-- <a-button size="large" class="text-black mx-4" type='link' @click="skipIntro">SKIP</a-button> -->
            <a-button :disabled="!this.baseImage.trim()" size="large" type='purple' class="back-button" @click="next" >FINISH</a-button>

        </portal>

        <div class="p5">
            <h3>This will appear as your base site plan image</h3>
            <div class="base-image-holder">
                <div class="base-image-box">
                    <p style="color:#9EA0A5">Standard Quality</p>
                    <ImageBox class="mt-3" :img="baseImage" @callback="updateImage" @sized="sizeImage"></ImageBox>
                </div>
                <div class="base-image-box add-margin-top">
                    <p style="color:#9EA0A5">High resolution (for touchscreen and siteplan table)</p>
                    <ImageBox class="mt-3" :img="highRes" @callback="updateHighRes" @sized="sizeHighRes"></ImageBox>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import ImageBox from '@/components/siteplan/ImageBox'

export default {
    components:{ImageBox},
    props:{
        value:Number
    },
    data() {
        return{
            baseImage:'',
            sendImg:{},
            siteplanCreated:false,
            storeRules:{},
            highRes:'',
            sendHighRes:{}
        }
    },
    computed:{
        instance() {
            return this.$store.state.instance
        },
    },
    watch:{
        currentStep(val){
            return this.$emit('input',val)
        },
        siteplanCreated:{
            handler(val) {
                console.log('this createdddddd' ,val)
                if (val) {
                    console.log('THIS STORE STATE SITEPLAN RULES', this.storeRules)
                    console.log('THIS STORE STATE SITEPLAN RULES LENGTH', Object.keys(this.storeRules).length)
                    if (this.storeRules && Object.keys(this.storeRules).length !=0) {
                        console.log('applying')
                        this.$api.post(`/architectural-controls/:instance/${this.$store.state.siteplan.appData.id}`, this.storeRules).then( ({data}) => {
                            this.$store.commit('APPLY_RULES', data)
                            // this.$api.put(`/siteplans/:instance/${this.$store.state.siteplan.appData.id}`, {options:{architecturalControl:data.id}})
                            this.$emit('next')
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else {
                        this.$emit('next')
                    }
                }
            }
        }
    },
    methods:{
        sizeImage(data) {
            this.sendImg.width = data.width
            this.sendImg.height = data.height
        },
        sizeHighRes(data) {
            this.sendHighRes.width = data.width
            this.sendHighRes.height = data.height
        },
        next() {
            let width=this.sendImg.width
            let height=this.sendImg.height
            console.log('highres', this.highRes, this.sendHighRes.width, this.sendHighRes.height)
            this.$api.post(`/siteplans/${this.instance.id}`, {name:this.instance.name,instance:this.instance.id, image:this.baseImage, width, height, buildingType:'models'}).then( ({data}) => {
                this.$api.put(`/siteplans/:instance/${data.id}`, {options:{media:{hq:this.highRes, width:this.sendHighRes.width, height:this.sendHighRes.height}}}).then(({highresData}) => {
                    console.log('added high res image',highresData )
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
                console.log('DATAAAAAAA', data)
                this.$store.dispatch('SET_APPDATA',{tags:[],siteplan:data})
                this.siteplanCreated = true
            }).catch( err => {
                let error = this.$err(err)
                this.$message.error(error)
            })
        },
        updateImage(data) {
            this.baseImage = data
            this.sendImg.image = data
        },
        updateHighRes(data) {
            this.highRes = data
            this.sendHighRes.image = data
        },
        skip(){
            return this.currentStep++
        },
        finish(){

            //do something with current Step and then move to the next page
            this.$store.commit('LOAD')
            this.$emit('done')
        },

    },
    created(){
        if (this.$store.state.siteplan.rules && Object.keys(this.$store.state.siteplan.rules).length !=0) {
            this.storeRules = this.$store.state.siteplan.rules
        }
    }
}
</script>

<style scoped>
@media screen and (max-width:1600px) {
  .base-image-box{
        width:100%;
    }
  .add-margin-top{
        margin-top:55px;
    }
}
@media screen and (min-width:1600px) {
    .base-image-holder{
        display:flex;
        align-items: center;
    }
    .base-image-box{
        width:50%;
    }
    .add-margin-top{
        margin-top:0;
        margin-left:10px;
    }
}
</style>
