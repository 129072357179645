<template>
    <div>
        <portal to="destination">
            <a-button size="large" class="text-black mx-4" type='link' @click="skip">MAYBE LATER</a-button>
            <a-button :disabled="control.elevSide == 'Choose an answer below:' || control.sameElevLotSpaces == 'Choose an answer below:' || control.elevPer10 == 'Choose an answer below:' || control.elevStreetPercentage == 'Choose an answer below:' || control.sameElevAcross == 'Choose an answer below:' || control.sameBrickLotSpaces == 'Choose an answer below:' || control.sameBrickAcross == 'Choose an answer below:'" size="large" type='purple' class="back-button" @click="next" >NEXT</a-button>

        </portal>
        <div>
            <a-form style="margin-top:50px; margin-left:70px; margin-right:70px">
                <div class="mt-3">
                    <h4>How many times can the same Home Model reoccur side by side?</h4>
                    <a-select v-model="control.modelSide" style="width: 100%">
                        <a-select-option :value="-1" >Does not Apply</a-select-option>
                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                            {{i === 1? 'None' : `${i} in a row`}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h4>How many of the same Home Models can be sited per a block of 10?</h4>
                    <a-select v-model="control.modelPer10" style="width: 100%">
                        <a-select-option :value="-1" >Does not Apply</a-select-option>
                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                            {{i === 1? 'Only one' : `${i} out of 10`}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h4>How many times can the same Home Model Elevation reoccur side by side?</h4>
                    <a-select placeholder="select" v-model="control.elevSide" style="width: 100%">
                        <a-select-option :value="-1" >Does not Apply</a-select-option>
                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                            {{i === 1? 'None' : `${i} in a row`}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h4>How many of the same Home Model Elevations can be sited per a block of 10?</h4>
                    <a-select v-model="control.elevPer10" style="width: 100%">
                        <a-select-option :value="-1" >Does not Apply</a-select-option>
                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                        {{i === 1? 'Only one' : `${i} out of 10`}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h4>What is the maximum percentage of occurrence of the same Home Model Elevation in a streetscape?</h4>
                    <a-select v-model="control.elevStreetPercentage" style="width: 100%">
                        <a-select-option :value="-1" >Does not Apply</a-select-option>
                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                        {{i * 10}} %
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h4>How many lots need to be between identical elevations?</h4>
                    <a-select v-model="control.sameElevLotSpaces" style="width: 100%">
                        <a-select-option :value="-1" >Does not Apply</a-select-option>
                        <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                        {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h4>Can identical Elevations be across from each other?</h4>
                    <a-select v-model="control.sameElevAcross" style="width: 100%">
                        <a-select-option :value="1" >Yes</a-select-option>
                        <a-select-option :value="0" >No</a-select-option>
                    </a-select>
                </div>
                <div class="mt-5">
                    <h4>Identify the number of lot spaces required between identical architectural colour packages.</h4>
                    <a-select v-model="control.sameBrickLotSpaces" style="width: 100%">
                        <a-select-option :value="-1" >Does not Apply</a-select-option>
                        <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                        {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mt-5 mb-5">
                    <h4>Can identical Architectural Color Package be across from each other?</h4>
                    <a-select v-model="control.sameBrickAcross" style="width: 100%">
                        <a-select-option :value="-1" >Does not apply</a-select-option>
                        <a-select-option :value="1" >Yes</a-select-option>
                        <a-select-option :value="0" >No</a-select-option>
                    </a-select>
                </div>
            </a-form>
        </div>

    </div>
</template>

<script>

export default {
    props:{
        value:Number
    },
    data:() => ({
        currentStep:0,
    }),
    data() {
        return {
            control:{},
        }
    },
    computed:{
        instance () {
            return this.$store.state.instance
        },
        siteplan(){
            return this.$store.state.siteplan.appData
        },
        rules() {
            return this.$store.state.siteplan.rules
        }
    },
    watch:{
        currentStep(val){
            return this.$emit('input',val)
        }
    },
    methods:{
        skip(){
            return this.$emit('next')
        },
        next(){
            console.log('rulesss', this.control)
            this.$store.commit('APPLY_RULES', this.control)
            this.$emit('next')
            // this.$api.post(`/architectural-controls/${this.instance.id}/${this.siteplan.id}`, this.control).then( ({data}) => {
            //     this.$store.commit('APPLY_RULES', data)
            //     console.log('applying rules')
            //     this.$emit('done')
            // })

        },
        req(msg) {
            return {required:true, message:msg}
        }

    },
    created() {
        console.log('runn', this.rules)
        if (this.rules && Object.keys(this.rules).length != 0) {
            this.control = this.rules
        } else {
            this.control = {
                elevSide:'Choose an answer below:',
                sameElevLotSpaces:'Choose an answer below:',
                elevPer10:'Choose an answer below:',
                elevStreetPercentage:'Choose an answer below:', //10-50
                sameElevAcross:'Choose an answer below:',
                sameBrickLotSpaces:'Choose an answer below:',
                sameBrickAcross:'Choose an answer below:',
                modelSide:'Choose an answer below:',
                modelPer10:'Choose an answer below:'
            }
        }
    }
}
</script>

<style>

</style>
